export { default as Curve } from "./curve";
export { default as CircledCheck } from "./circledCheck";
export { default as MrvLogo } from "./mrvLogo.svg";
export { default as MrvOutlineLogo } from "./mrvOutlineLogo.svg";
export { default as CircledX } from "./circledX";
export { default as Building } from "./building.svg";
export { default as BuildingMobile } from "./building-mobile.svg";
export { default as Check } from "./check";
export { default as Check2 } from "./check2";
export { default as GrayCircle } from "./grayCircle";
export { default as GreenCheck } from "./greenCheck";
export { default as RedX } from "./redX";
export { default as Keys } from "./keys";
export { default as ArrowLeft } from "./arrowLeft";
export { default as ArrowRight } from "./arrowRight";
export { default as EstruturaSvg } from "./estrutura.svg";
export { default as FundacaoSvg } from "./fundacao.svg";
export { default as AcabamentoSvg } from "./acabamento.svg";
export { default as AlvenariaSvg } from "./paredes.svg";
export { default as AreaComumSvg } from "./areacomum.svg";
export { default as EstruturaFeed } from "./estruturaFeed.svg";
export { default as FundacaoFeed } from "./fundacaoFeed.svg";
export { default as AcabamentoFeed } from "./acabamentoFeed.svg";
export { default as AlvenariaFeed } from "./paredesFeed.svg";
export { default as AreaComumFeed } from "./areaComumFeed.svg";
export { ReactComponent as DownloadLogoSvg } from "./downloadLogo.svg";
export { default as FacebookLogoSvg } from "./icon-social-facebook.svg";
export { default as InstagranLogoSvg } from "./icon-social-instagram.svg";
export { default as YouTubeLogoSvg } from "./icon-social-youtube.svg";
export { default as Outgoing } from "./outgoing.svg";
export { default as Heart } from "./heart";
export { default as Plant } from "./plant";
export { default as BrokenPolaroid } from "./brokenPolaroid";
export { default as ErrorSvg } from "./ilustra-error.svg";
export { default as HandHoldingHeart } from "./handHoldingHeart";
export { default as Clock } from "./clock.svg";
export { default as DeliveryKeys } from "./deliveryKeys.svg";
export { default as Survey } from "./survey.svg";
export { default as VisitBuilding } from "./visitBuilding.svg";
export {
  default as MilestonesAbout
} from "./illustration-milestones-about.svg";
export { default as IconFinantialDoc } from "./icon-doc-finantial.svg";
export { default as IconIdDoc } from "./icon-id-doc.svg";
export { default as IconRealEstateDoc } from "./icon-doc-real-estate.svg";
export { default as IconShower } from "./icon-shower.svg";
export { default as IconFurniture } from "./icon-furniture.svg";
export { default as IconShoes } from "./icon-shoes.svg";
export { default as IconDoor } from "./icon-door.svg";
export { default as IconWindow } from "./icon-window.svg";
export { default as IconWarning } from "./icon-warning.svg";
export { default as IconDocModal } from "./icon-doc-modal.svg";
export {
  default as IllustrationMilestonesVisit
} from "./illustration-milestones-visit.svg";
export {
  default as IllustrationMilestonesInspection
} from "./illustration-milestones-inspection.svg";
export {
  default as IllustrationMilestonesCongratulations
} from "./illustration-milestones-congratulations.svg";
export { default as Reload } from "./reload.svg";
export { default as Notifications } from "./notifications.svg";
export {
  default as RecommendationSvg
} from "./illustration-recommendation.svg";
export {
  default as RecommendationBannerIllustration
} from "./illustration-banner-recommendation.svg";
export { default as IconCoupon } from "./icon-coupon.svg";
export { default as IconCouponEmail } from "./icon-coupon-email.svg";
export { default as IconCouponAwarded } from "./icon-coupon-awarded.svg";
export { default as IconCouponUtilized } from "./icon-coupon-utilized.svg";
export { default as ExpiredCoupon } from "./expiredCoupon.svg";
export { default as ApprovedCoupon } from "./approvedCoupon.svg";
export { default as DeniedCoupon } from "./deniedCoupon.svg";
export { default as WelcomeDay } from "./welcomeDay.svg";
export { default as WelcomeNight } from "./welcomeNight.svg";
export {
  default as IllustrationConstructions
} from "./illustration-constructions.svg";
export { default as IllustrationKit } from "./illustration-kit.svg";
export { default as IllustrationFinancial } from "./Financeiro.svg";
export { default as IllustrationMia } from "./mia.svg";
export { default as NewErrorSvg } from "./newError.svg";
export { default as IncomeTaxIllustration } from "./incomeTax.svg";
export { default as Broken } from "./broken.svg";
export { default as Skyscraper } from "./skyscraper";
export { default as Certificate } from "./certificate";
export { default as DownloadOutline } from "./downloadOutline";
export { default as NoteSearch } from "./note-search.svg";
export { default as ProfileQuited } from "./profile-quited.svg";
export { default as TermSignSuccess } from "./term-sign-success.svg";
export { default as IndicacaoSitWoman } from "./indicacao-sit-woman.svg";
export { default as IndicacaoMoney } from "./indicacao-money.svg";
export { default as IndicacaoWomanWallet } from "./indicacao-woman-wallet.svg";
export { default as IndicacaoVisit } from "./indicacao-visit.svg";
export { default as Tooltip } from "./tooltip";
export { default as Gift } from "./gift";
export { default as IndicacaoP1 } from "./indicacao_p1.svg";
export { default as IndicacaoP2 } from "./indicacao_p2.svg";
export { default as IndicacaoP3 } from "./indicacao_p3.svg";
export { default as IndicacaoP4 } from "./indicacao_p4.svg";
export { default as IconDots } from "./icon_dots.svg";
export { default as IconShare } from "./icon_share.svg";
export { default as Star } from "./star.svg";
export { default as ClockOrange } from "./clock_orange.svg";
export { default as WomanSitWithoutIcons } from "./woman_sit_without_icons.svg";
export {
  default as BannerBackgroundIndicacao
} from "./banner-background-indicacao.svg";
export {
  default as GroupBackgroundIndicacao
} from "./group-background-indicacao.svg";
export { default as TicketWin } from "./ticket-win.svg";
export { default as TicketNotWin } from "./ticket-not-win.svg";
export { default as PersonagemIndicacao } from "./personagem-indicacao.svg";
export { default as IconSocialInstagram } from "./icon-social-instagram.svg";
export {
  default as IconSocialInstagramDisabled
} from "./icon-social-instagram-disabled.png";
export {
  default as ImageShareInstagranMessage
} from "./image-share-instagran-message.png";
export {
  default as ImageShareInstagranFeed
} from "./image-share-instagran-feed.png";
export {
  default as ImageShareInstagranStories
} from "./image-share-instagran-stories.png";
export { default as HearthShare } from "./hearth-share.svg";
export { default as HashTagShare } from "./hash-tag-share.svg";
export { default as GiftShare } from "./gift-share.svg";
export { default as LinkShare } from "./link-share.svg";
export { default as ImageFeedFull } from "./feed_full.jpg";
export { default as ImageStoriesFull } from "./stories_full.jpg";
export { default as BoletoEExtrato } from "./newStayIn/boleto_e_extrato.svg";
export {
  default as AnteciparParcelas
} from "./newStayIn/antecipar_parcelas.svg";
export { default as AcompanharObra } from "./newStayIn/acompanhar_obra.svg";
export {
  default as AnteciparParcelasBig
} from "./newStayIn/antecipar_parcelas_big.svg";
export { default as MundoDaCasa } from "./newStayIn/mundo_da_casa.svg";
export { default as Armarios } from "./newStayIn/armarios.svg";
export { default as MundoDaCasaIcon } from "./newStayIn/mundo_da_casa_icon.svg";
export {
  default as DescontosExclusivos
} from "./newStayIn/descontos_exclusivos.svg";
export { default as TesteDeEstilos } from "./newStayIn/teste_de_estilos.svg";
export { default as PerfilNegociacao } from "./newStayIn/perfil_negociacao.svg";
export {
  default as PerfilNegociacaoBig
} from "./newStayIn/perfil_negociacao_big.svg";
export {
  default as AcompanharObraBig
} from "./newStayIn/acompanhar_obra_big.svg";
export {
  default as AssistenciaTecnica
} from "./newStayIn/assistencia_tecnica.svg";
export {
  default as BoletoEExtratoBig
} from "./newStayIn/boleto_e_extrato_big.svg";
export { default as MiaAntecipacao } from "./mia-antecipacao.svg";
export {
  default as IndicacaoSitWomanMenu
} from "./indicacao-sit-woman-menu.svg";
export { default as Money } from "./money.svg";
export { default as MenuArrow } from "./menu-arrow.svg";
export { default as Simulador1 } from "./newStayIn/simulador1.svg";
export { default as Simulador2 } from "./newStayIn/simulador2.svg";
export { default as Simulador3 } from "./newStayIn/simulador3.svg";
export { default as HeaderIcon } from "./newStayIn/headerIcon.svg";
export {
  default as ConhecaOMundoDaCasa
} from "./newStayIn/mdc/conheca_o_mundo_da_casa.png";
export {
  default as AproveiteDescontosExclusivos
} from "./newStayIn/mdc/aproveite_descontos_exclusivos.png";
export {
  default as DescubraOSeuEstilo
} from "./newStayIn/mdc/descubra_o_seu_estilo.png";
export {
  default as EconomizeNaContaDeLuz
} from "./newStayIn/mdc/economize_na_conta_de_luz.png";
export {
  default as PersonalizeAcabamentos
} from "./newStayIn/mdc/personalize_acabamentos.png";
export {
  default as EscolhaSeuProjetoDeArmarios
} from "./newStayIn/mdc/escolha_seu_projeto_de_armarios.png";
export { default as MiaSensia } from "./mia-sensia.svg";
export { default as MiaAntecipacaoSensia } from "./mia-antecipacao-sensia.svg";
export {
  default as AcompanharObraBigSensia
} from "./newStayIn/acompanhar_obra_sensia_big.svg";
export {
  default as BoletoEExtratoBigSensia
} from "./newStayIn/boleto_e_extrato_sensia_big.svg";
export {
  default as AnteciparParcelasBigSensia
} from "./newStayIn/antecipar_parcelas_sensia_big.svg";
export {
  default as AcompanharObraSensia
} from "./newStayIn/acompanhar_obra_sensia.svg";
export {
  default as BoletoEExtratoSensia
} from "./newStayIn/boleto_e_extrato_sensia.svg";
export {
  default as AnteciparParcelasSensia
} from "./newStayIn/antecipar_parcelas_sensia.svg";
export {
  default as AssistenciaTecnicaSensia
} from "./newStayIn/assistencia_tecnica_sensia.svg";
export { default as MundoDaCasaCard } from "./mundo_da_casa.svg";

export { default as Key } from "./newStayIn/key.svg";

export { default as Tag } from "./newStayIn/tag.svg";

export {
  default as FiquePorDentroBackground
} from "./newStayIn/fique_por_dentro_background.svg";

export { default as MDCIcon } from "./mdc_icon.svg";

export { default as IRIcon } from "./IRIcon.svg";

export { default as AlertRed } from "./alertRed.svg";

export { default as IRIconSensia } from "./IRIconSensia.svg";
