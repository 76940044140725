import React, { useEffect, useState } from "react";
import { IllustrationKit, ErrorSvg, AlertRed } from "assets/svg";
import countService from "services/countService";
import plugin from "utils/currentPlugin";
import { getThemeName, getThemePropertyValue } from "utils/pluginHelpers";
import { LoadingIndicator } from "@company-mrv/mrv-design-system/components";
import {
  ContainerImage,
  Footer,
  IllustrationImage,
  Text,
  Title,
  Modal,
  Container,
  Content,
  ButtonRefresh,
  ContainerLoadingIcon,
  TitleInTime,
  TextInTime
} from "./styles";

export const STATUS = {
  ERROR_500: "ERROR_500",
  IN_TIME_WAITING: "IN_TIME_WAITING",
  TIMEOUT: "TIMEOUT",
  ALL_OK: "ALL_OK"
};

export const ACTION = {
  TIMEOUT: "TIMEOUT",
  ALL_OK: "ALL_OK"
};

export const ON_CLICK_BUTTON_ACTION = {
  TRY_AGAIN: "TRY_AGAIN",
  OK_I_WILL_WAITING: "OK_I_WILL_WAITING",
  CLOSE: "CLOSE"
};

export const TEXT_BY_TIME = [
  {
    time: 1,
    text: "Confirmando seus dados..."
  },
  {
    time: 3,
    text: "Gerando sua documentação..."
  }
];

const ModalLoading = ({
  open,
  onButtonClick,
  status,
  onAction,
  textsByTimeOverride
}) => {
  let TEXT_BY_TIME_LOCAL = TEXT_BY_TIME;

  const [text, setText] = useState(TEXT_BY_TIME_LOCAL[0].text);
  const [subText, setSubText] = useState(null);

  useEffect(() => {
    if (status === STATUS.ALL_OK) {
      setTimeout(() => {
        onAction(ACTION.ALL_OK);
      }, 2000);
    }
  }, [status]);

  if (textsByTimeOverride) {
    TEXT_BY_TIME_LOCAL = textsByTimeOverride;
  }

  const getIconTop = () => {
    const valueImage = getThemePropertyValue(
      "anticipation.alertModal.imageName",
      "AlertCircle"
    );

    if (valueImage === "Observe") {
      return <img src={AlertRed} alt="Alert Red" />;
    }

    return (
      <ContainerImage
        h={117}
        w={143}
        pt={40}
        data-testid={
          status === STATUS.ERROR_500 ? "error-image" : "error-clock-image"
        }
      >
        <IllustrationImage
          iv={status === STATUS.ERROR_500}
          src={status === STATUS.ERROR_500 ? ErrorSvg : IllustrationKit}
        />
      </ContainerImage>
    );
  };

  const getTitle = () => {
    if (status === STATUS.ERROR_500) {
      return <>Algo aconteceu e não conseguimos gerar o seu extrato.</>;
    }
    if (status === STATUS.TIMEOUT) {
      return (
        <>
          A geração do seu boleto está demorando mais que o normal, mas ainda
          estamos processando...
        </>
      );
    }
    return "";
  };

  const getMessage = () => {
    if (status === STATUS.ERROR_500) {
      return "Por favor, tente novamente.";
    }
    return "";
  };

  useEffect(() => {
    if (status === STATUS.IN_TIME_WAITING && open) {
      countService.doTimeCount(timeCurrent => {
        console.log("timecurrent:", timeCurrent);
        const foundText = TEXT_BY_TIME_LOCAL.find(
          item => item.time === timeCurrent
        );
        if (foundText && foundText.text) {
          setText(foundText.text);
        }
        if (foundText && foundText.subText) {
          setSubText(foundText.subText);
        }
        if (foundText && foundText.action && onAction) {
          onAction(foundText.action);
          countService.stopTimeCount();
        }
      });
    } else {
      countService.stopTimeCount();
    }
  }, [status]);

  const getButtonText = () => {
    if (status === STATUS.ERROR_500) {
      return "Tente novamente";
    }
    return "";
  };

  const contentError = () => (
    <>
      {getIconTop()}
      <Title data-testid="error-title">{getTitle()}</Title>
      <Text data-testid="error-message">{getMessage()}</Text>
      <Footer>
        <ButtonRefresh
          data-testid="error-button-text"
          theme={plugin ? plugin.name : ""}
          onClick={() => {
            if (status === STATUS.ERROR_500) {
              onButtonClick(ON_CLICK_BUTTON_ACTION.TRY_AGAIN);
            } else {
              onButtonClick(ON_CLICK_BUTTON_ACTION.OK_I_WILL_WAITING);
            }
          }}
        >
          {getButtonText()}
        </ButtonRefresh>
      </Footer>
    </>
  );

  const getText = () => {
    if (status === STATUS.ALL_OK) {
      return "Tudo Pronto!";
    }

    return text;
  };

  const getSubText = () => {
    if (status === STATUS.ALL_OK) {
      return "Seu extrato será baixado!";
    }

    return subText;
  };

  const contentTimeWaiting = () => (
    <>
      <ContainerLoadingIcon data-testid="loading-image">
        <LoadingIndicator theme={getThemeName()} />
      </ContainerLoadingIcon>
      <TitleInTime data-testid="error-title">{getText()}</TitleInTime>
      <TextInTime data-testid="error-message">
        {getSubText() || "Por favor, aguarde"}
      </TextInTime>
    </>
  );

  if (status === null) return null;

  return (
    <Modal isOpen={open} appElement={document.getElementById("react-view")}>
      {status === STATUS.ERROR_500 && (
        <Modal.Header
          onCloseClick={() => {
            onButtonClick(ON_CLICK_BUTTON_ACTION.CLOSE);
          }}
        />
      )}
      <Container data-testid="modal-error-header-container">
        <Content>
          {status === STATUS.IN_TIME_WAITING || status === STATUS.ALL_OK
            ? contentTimeWaiting()
            : contentError()}
        </Content>
      </Container>
    </Modal>
  );
};

export default ModalLoading;
