class CountService {
  idInterval;

  time = 1;

  doTimeCount = callback => {
    this.stopTimeCount();
    this.idInterval = setInterval(() => {
      callback(this.time);
      this.time += 1;
    }, 1000);
  };

  stopTimeCount = () => {
    this.time = 1;
    clearInterval(this.idInterval);
    this.idInterval = undefined;
  };
}

export default new CountService();
