import styled, { css } from "styled-components";
import {
  Modal as ModalCustom,
  Button as ButtonCustom
} from "@company-mrv/mrv-design-system/components";
import tokens from "@company-mrv/mrv-design-system/tokens";
import { media } from "utils/styles/breakpoints";
import { WhatsApp } from "@company-mrv/mrv-design-system/icons";

export const IllustrationImage = styled.img`
  ${props =>
    props.iv &&
    css`
      transform: rotateY(180deg);
    `}
`;

export const ContainerImage = styled.div`
  width: ${props => props.w && `${props.w}px`};
  height: ${props => props.h && `${props.h}px`};
  margin-top: ${props => props.pt && `${props.pt}px`};
`;

export const Modal = styled(ModalCustom)`
  .modal-content {
    width: auto;
    height: auto;
    .modal-header {
      align-items: flex-end;
      justify-content: stretch;
    }
    ${media.forDesktopUp} {
      max-width: 1032px;
      overflow-y: visible;
    }
  }
  ${media.forDesktopUp} {
    .modal-header button {
      margin-right: 4px;
      margin-top: 4px;
      transition: transform 0.3s ease;
      [data-whatintent="mouse"] &:hover {
        opacity: 1;
        transform: rotate(90deg);
      }
    }
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  min-height: 280px;
  width: 312px;
  padding: 16px;
  ${media.forTabletUp} {
    width: 328px;
    align-items: center;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

export const Title = styled.div`
  width: 218px;
  line-height: ${tokens.lineHeightMedium};
  font-weight: ${tokens.fontWeightBold};
  text-align: center;
  color: ${tokens.colorNeutralXxdark};
  font-size: ${tokens.fontSizeSmall};
  letter-spacing: 0;
  margin-bottom: 16px;
  margin-top: 40px;
`;

export const Text = styled.div`
  width: 218px;
  margin: 8px 27px 0 27px;
  text-align: center;
  min-height: 66px;
  font-weight: ${tokens.fontWeightRegular};
  color: ${tokens.colorNeutralXdark};
  font-size: ${tokens.fontSizeXSmall};
  line-height: ${tokens.lineHeightDefault};
  white-space: pre-line;
`;

export const TitleInTime = styled.div`
  width: 100%;
  line-height: ${tokens.lineHeightMedium};
  font-weight: ${tokens.fontWeightBold};
  text-align: center;
  color: ${tokens.colorNeutralXxdark};
  font-size: ${tokens.fontSizeSmall};
  margin-bottom: 8px;
  margin-top: 10px;
`;

export const TextInTime = styled.div`
  width: 100%;
  text-align: center;
  font-weight: ${tokens.fontWeightRegular};
  color: ${tokens.colorNeutralMedium};
  font-size: ${tokens.fontSizeXxxSmall};
  line-height: ${tokens.lineHeightMedium};
`;

export const Footer = styled.div`
  width: 100%;
`;

export const Link = styled.a`
  font-size: ${tokens.fontSizeXxSmall};
  font-weight: ${tokens.fontWeightBold};
  line-height: 24px;
  letter-spacing: 0;
  text-align: center;
  color: ${tokens.colorBrandPrimaryPure};
  border-bottom: 1px dotted ${tokens.colorBrandPrimaryPure};
  cursor: pointer;
`;

export const ButtonRefresh = styled(ButtonCustom)`
  margin-bottom: 20px;
  width: 256px;
`;

export const ButtonMia = styled(ButtonCustom)`
  margin-top: 13px;
  width: 100%;
  background-color: rgba(84, 188, 130, 0.16);
  color: ${tokens.colorBrandPrimaryPure};
`;

export const WhatsAppCustom = styled(WhatsApp)`
  > path {
    fill: ${tokens.colorBrandPrimaryPure} !important;
  }
`;

export const ContainerButtonWhats = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const ContainerImageWhats = styled.div`
  width: 20px;
  margin-left: 10px;
`;

export const ContainerLoadingIcon = styled.div`
  height: 42px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContainerTitleImage = styled.img`
  width: 60px;
  height: 60px;
  ${props =>
    props.marginTop &&
    css`
      margin-top: 30px;
    `}
`;

export default {
  ContainerTitleImage,
  TitleInTime,
  ContainerLoadingIcon,
  ContainerImageWhats,
  ContainerButtonWhats,
  WhatsAppCustom,
  ContainerImage,
  ButtonRefresh,
  Footer,
  Link,
  Modal,
  Title,
  Container,
  Content,
  Text,
  IllustrationImage,
  ButtonMia
};
