import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { parse } from "query-string";
import { useLocation, useHistory } from "react-router-dom";
import {
  MeuApeWrapper as BaseMeuApeWrapper,
  MeuApeApp,
  RuntimeEnviroment,
  GtmPageProvider,
  GtmCategoryProvider
} from "@company-mrv/mrv-design-system/components";
import AuthContext from "contexts/auth";
import ContractContext from "contexts/contract";
import GeneralContext from "contexts/general";
import { ROUTES, REACT_APP_ENV } from "utils/constants";
import { isRoutePublic } from "utils/functions";
import { getThemeName } from "utils/pluginHelpers";
import {
  trackException,
  trackTrace,
  trackTraceException
} from "services/trackInsights";

const MeuApeWrapper = ({ children }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { user, logout } = useContext(AuthContext);
  const { contracts, selectedContract, setSelectedContract } = useContext(
    ContractContext
  );
  const {
    setGeneralLoading,
    redirect,
    financialLink,
    setRedirectFinancialLink
  } = useContext(GeneralContext);
  const [authUser, setAuthUser] = useState(user);

  useEffect(() => {
    if (isRoutePublic(pathname)) {
      setAuthUser(null);
    } else {
      setAuthUser(user);
    }
  }, [pathname, user]);

  const isQueryParameterDefined = value => {
    // em alguns momentos, o contractId vinha como uma string
    // undefined, o que gerava problemas mais adiante no código
    // esse método corrige este bug
    return (
      value != null && value !== "" && value !== "undefined" && value !== "null"
    );
  };

  const getSelectedContract = contractsList => {
    const params = parse(history.location.search);
    let selectContract;
    if (isQueryParameterDefined(params.contractId)) {
      trackTrace(
        `Contract Id obtido a partir da url, valor: ${params.contractId}`
      );
      selectContract = contractsList.find(
        contract => contract.unidadeId === params.contractId
      );
    }
    return selectContract || contractsList[0];
  };

  const getName = contract => {
    return contract.nomeCompletoUnidade;
  };
  const getUnitList = () => {
    const newContracts = contracts.map(contract => ({
      ...contract,
      id: contract.unidadeId,
      name: getName(contract) || "",
      isBeta: true,
      empreendimentoId: contract.empreendimentoId
    }));

    if (
      newContracts.length > 0 &&
      selectedContract &&
      !selectedContract.nomeEmpreendimento
    ) {
      const selectContract = getSelectedContract(newContracts);

      setSelectedContract(selectContract);
      setRedirectFinancialLink(selectContract);
    } else {
      setRedirectFinancialLink(selectedContract);
    }

    return newContracts;
  };

  const changeCurrentUnit = async contractSelected => {
    if (contractSelected.isGarageContract) {
      redirect(financialLink, true);
    } else {
      try {
        setSelectedContract(contractSelected);
        setRedirectFinancialLink(contractSelected);
      } catch (error) {
        trackException(error);
        trackTraceException("changeCurrentUnit", error);
        history.push({
          pathname: ROUTES.ERROR,
          state: { detail: "changeCurrentUnit" }
        });
      }
    }
  };

  const handleLogout = async () => {
    setGeneralLoading(true);
    await logout();
  };

  const hideHeaderAndFooter =
    pathname === ROUTES.LOGIN || pathname === ROUTES.LOGOUT;
  const currentUnit = selectedContract
    ? {
        ...selectedContract,
        id: selectedContract.unidadeId,
        name: getName(selectedContract) || "",
        isBeta: true,
        empreendimentoId: selectedContract.empreendimentoId
      }
    : "";

  const checkRuntimeEnviroment = () => {
    switch (REACT_APP_ENV) {
      case "DEV":
        return RuntimeEnviroment.Development;
      case "QAS":
        return RuntimeEnviroment.Qas;
      case "PRD":
        return RuntimeEnviroment.Production;
      case "SIM":
        return RuntimeEnviroment.Sim;
      default:
        return null;
    }
  };

  return (
    <GtmPageProvider page="Relacionamento">
      <GtmCategoryProvider category="Menu">
        <BaseMeuApeWrapper
          authToken={authUser}
          currentUnit={currentUnit}
          currentApplication={MeuApeApp.Relacionamento}
          unitList={getUnitList()}
          onCurrentUnitChange={changeCurrentUnit}
          onLogout={handleLogout}
          hideHeader={hideHeaderAndFooter}
          hideFooter={hideHeaderAndFooter}
          runtimeEnvironment={checkRuntimeEnviroment()}
          theme={{
            name: getThemeName()
          }}
        >
          {children}
        </BaseMeuApeWrapper>
      </GtmCategoryProvider>
    </GtmPageProvider>
  );
};

MeuApeWrapper.propTypes = {
  children: PropTypes.node.isRequired
};

export default MeuApeWrapper;
