import { API_FINANCEIRO_NAME, ENDPOINT_ONBOARDING } from "utils/constants";
import { axiosInstance } from "../servicesSetup";

export const getOnboardingInformation = async (isIndicacao = false) => {
  const instance = await axiosInstance(null, API_FINANCEIRO_NAME);
  const config = {
    withCredentials: true
  };
  if (isIndicacao) {
    config.params = {
      type: "indicacao"
    };
  }
  return instance.get(ENDPOINT_ONBOARDING, config);
};

export const getEnterprises = async () => {
  const instance = await axiosInstance(null, API_FINANCEIRO_NAME);
  const { data } = await instance.get(`/empreendimentos/`);

  return data;
};

export const getPDFExtract = async empreendimentoId => {
  const instance = await axiosInstance(null, API_FINANCEIRO_NAME);
  const { data } = await instance.get(
    `/empreendimentos/${empreendimentoId}/extrato/pdf`,
    {
      responseType: "blob"
    }
  );

  return data;
};

export const getPDFExtractIR = async (
  empreendimentoId,
  cpfUser,
  contratoSap
) => {
  const instance = await axiosInstance(null, API_FINANCEIRO_NAME);
  const { data } = await instance.post(
    `/perfil/extrato/download`,
    {
      IdEmpreendimento: empreendimentoId,
      CpfUsuario: cpfUser,
      ContratoSap: contratoSap
    },
    {
      responseType: "blob",
      headers: {
        "api-version": "2.0"
      }
    }
  );

  return data;
};
